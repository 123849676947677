(function() {

    /*const setCookie = (cname, cvalue, exdays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        const expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }*/

    /*const updateSocialMediaLinks = () => {
        const inputElement = document.getElementById('copylink');
        let url = 'http://testwalee.com';

        if (inputElement && inputElement.value){
            url = inputElement.value;
        }

        const text = encodeURIComponent('');

        document.querySelector('.fa-facebook').parentElement.href = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
        document.querySelector('.fa-x-twitter').parentElement.href = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
        document.querySelector('.fa-pinterest-p').parentElement.href = `https://pinterest.com/pin/create/button/?url=${url}&description=${text}`;
        document.querySelector('.fa-telegram').parentElement.href = `https://t.me/share/url?url=${url}&text=${text}`;
    }*/

    /*const getCookie = (cname) => {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (const c of ca) {
            if (c.trim().startsWith(name)) {
                return c.substring(name.length).trim();
            }
        }
        return "";
    }

    const setLangCookie = (language) => {
        if (language) {
            $('.langBtn').parent().removeClass('is-active');
            $(`.langBtn[data-lang="${language}"]`).parent().addClass('is-active');
            setCookie("walee-ui-lang", language, 365);
            location.reload();
        }
    }

    const selectedLang = getCookie('walee-ui-lang') || 'en_EN';

    $(`.langBtn[data-lang="${selectedLang}"]`).parent().addClass('is-active');
*/
    const body = document.querySelector('body');
    const inputField = document.querySelector('#fullUrlField');
    const form = document.querySelector('form');
    const shortUrlField = document.querySelector('#copylink');
    const shortenBtn = document.querySelector('#shortenBtn');
    const copyUrlToBufferBtn = document.querySelector('#copyUrlToBufferBtn');
    const darkModeSwitch = document.querySelector('#darkModeSwitch');
    const langBtns = document.querySelectorAll('.langBtn');

 /*   langBtns.forEach(btn => {
        btn.addEventListener('click', (e) => {
            e.preventDefault();
            const lang = btn.getAttribute('data-lang');
            setLangCookie(lang);
        });
    });
*/
    /*if (localStorage.getItem('yourls-theme') === 'dark') {
        body.classList.add('theme-dark');
        darkModeSwitch.checked = true;
    }*/

    const copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    /*if (inputField) {
        inputField.addEventListener('input', function () {
            if (this.value) {
                shortenBtn.removeAttribute('disabled');
            } else {
                shortenBtn.setAttribute('disabled', 'disabled');
            }
        });
    }*/

    /*if (darkModeSwitch) {
        darkModeSwitch.addEventListener('change', function () {
            if (this.checked) {
                body.classList.add('theme-dark');
                localStorage.setItem('yourls-theme', 'dark');
            } else {
                body.classList.remove('theme-dark');
                localStorage.setItem('yourls-theme', 'light');
            }
        });
    }*/

    if (copyUrlToBufferBtn) {
        copyUrlToBufferBtn.addEventListener('click', (e) => {
            e.preventDefault();
            const url = shortUrlField.value;
            copyToClipboard(url);
        });
    }

    /*if (shortenBtn) {
        shortenBtn.addEventListener('click', () => {
            inputField.setAttribute('readonly', 'readonly');
            this.setAttribute('disabled', 'disabled');
        });
    }*/

    //updateSocialMediaLinks();

})();